import { reactive } from 'vue';

export const useInputValidation = (fieldName : string)=> {
  const state = reactive({
    hasError: false,
    errorMessage: ''
  });

  const regex = /^[a-zA-Z0-9 -:\n]*$/;

  const validate = (inputValue : string) => {
    if (!regex.test(inputValue)) {
      state.hasError = true;
      state.errorMessage = `Invalid input for ${fieldName} special characters are not allowed.`;
      return false;
    } else {
      state.hasError = false;
      state.errorMessage = ''
      return true;
    }
  };
  return {
    state,
    validate,
  };
}
